
.siw-main-view {
  .okta-form-subtitle {
    text-align: center;
  }
}

.authenticator-enroll-list {
  .authenticator-description {
    width: 100%;
  }
  .list-content {
    .authenticator-list-title {
      margin-bottom: $font-size-header;
      font-weight: $font-weight-labels;
    }
  }
  .skip-all {
    width: 100%;
    text-align: center;
    margin-bottom: 15px;
  }
}

.authenticator-enroll-list .authenticator-verify-list {
  .authenticator-button {
    padding-top: 10px;
    @include device-mq(x-small) {
      width: 100%;
      margin-top: 10px;
    }
    .button {
      margin-top: 0;
      height: 30px;
      line-height: 28px;
      padding: 0 10px;
      @include device-mq(x-small) {
        width: 100%;
        text-align: center;
      }
    }
  }
}

.authenticator-enrollment-nickname {
  text-overflow: ellipsis;
}

.authenticator-verify-list {
  .authenticator-description {
    display: flex;
    flex-direction: row;
    align-items: center;

    > div {
      margin: 0 5px;
    }

    .authenticator-button {
      padding: 0;
    }

    .authenticator-label {
      width: 175px;
    }

    .authenticator-label--small {
      line-height: 1;
    }
  }
}

.authenticator-row {
  margin-bottom: 30px;
  display: flex;
  align-items: center;

  .authenticator-usage-text {
    color: $light-text-color;
  }

  .authenticator-button {
    padding-top: 10px;
    @include device-mq(x-small) {
      width: 100%;
      margin-top: 10px;
    }
    .button {
      margin-top: 0;
      height: 30px;
      line-height: 28px;
      padding: 0 10px;
      @include device-mq(x-small) {
        width: 100%;
        text-align: center;
      }
    }
  }

  .authenticator-icon-container {
    align-self: flex-start;
    margin-right: 20px;
  }

  .authenticator-icon {
    width: 38px;
    height: 38px;
    border: 1px solid #ccc;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 38px 38px;
  }

  .success-16-green,
  .success-16-gray {
    width: 20px;
    height: 20px;
    line-height: 24px;
    flex-shrink: 0;
  }

  .success-16-green:before,
  .success-16-gray:before {
    font-size: 20px;
  }

}
